import React from 'react';
import { getId } from 'office-ui-fabric-react';
import { Dictionary, ISortingProps } from "../../entities/common";
import * as ViewsStore from '../../store/views';
import * as Metadata from '../../entities/Metadata';
import { EntityChevron } from '../common/extensibleEntity/EntityGroupHeader';

const CHEVRON_WIDTH = 29;
export interface IHierarchyItem { hKey: string; hParentKey?: string; }

type HierarchyManagerProps<T extends {id: string},TContext> = {
    fieldId: string;
    collapseItemsOnSorting?: boolean;
    allowDrag?: () => boolean;
    isHierarchicalSort?: () => boolean;
    filterRootHierarchyItems?: (items: T[]) => T[];
    getDefaultOutlineLevel?: () => number;
    isHierarchyView?: (context: TContext | undefined) => boolean;
    getItemId: (item: T) => string | null;
    getItemParentId: (item: T) => string | null | undefined;
    getItemIsParent?: (item: T) => boolean;
    getItemCategory?: (item: T) => number;
}

type HierarchyData<T extends { id: string } & IHierarchyItem> = {
    level?: number;
    item: T;
    childItems: T[];
}
export type Sorter<T> = (orderby: ViewsStore.IOrderBy | ViewsStore.IOrderBy[]) => (a: T, b: T) => number;

export class HierarchyManager<T extends { id: string }, TContext> {
    private _props: HierarchyManagerProps<T, TContext>;
    private _onChange: () => void = () => { };
    private _sorter: Sorter<T> = () => () => 0;
    private _items: (T & IHierarchyItem)[] = [];
    private allEntities: T[] = [];
    private _orderBy: ViewsStore.IOrderBy | ViewsStore.IOrderBy[] | undefined;
    private _isHierarchyView: boolean = true;
    private _shownChildrenCount: number = 0;
    private _expanded: Dictionary<HierarchyData<T & IHierarchyItem>> = {};

    constructor(props: HierarchyManagerProps<T, TContext>) {
        this._props = props;
    }

    setup = (sorter: Sorter<T>, onChange: () => void, orderBy?: ViewsStore.IOrderBy | ViewsStore.IOrderBy[]) => {
        this._sorter = sorter;
        this._onChange = onChange;
        this._orderBy = orderBy;
    }
    
    getExpandedChildrenCount = () => this._shownChildrenCount;
    getKey = (entity: T & IHierarchyItem) => entity.hKey || entity.id;

    setItems = (entities: T[], allEntities: T[], context?: TContext, keepExpanded?: boolean | string[]) => {
        this._shownChildrenCount = 0;
        const expandedIds = keepExpanded === true ? this.getExpandedIds() : Array.isArray(keepExpanded) ? keepExpanded : [];
        this._expanded = {};
        this.allEntities = allEntities;
        this._isHierarchyView = this._props.isHierarchyView ? this._props.isHierarchyView(context) : false;
        this._orderBy = this._isHierarchyView ? undefined : this._orderBy;

        this._items = (this._isHierarchyView && this._props.filterRootHierarchyItems ? this._props.filterRootHierarchyItems(entities) : entities)
            .map(_ => this._buildHierarchyItem(_));
        if (this._isHierarchyView) {
            let expandLevel = this._props.getDefaultOutlineLevel?.() || 0;
            expandLevel && this._items.forEach(_ => this._expand(_, expandLevel));
        }
        if (expandedIds.length) {
            const map = this._items.reduce((cum, cur) => ({ ...cum, [cur.id]: cur }), {});
            expandedIds.forEach(_ => map[_] && this._expand(map[_], 0, expandedIds));
        }

        this._onChange();
    }

    getExpandedIds = (): string[] => {
        return Object.keys(this._expanded).map(_ => this._expanded[_].item.id);
    }

    getSortingProps = (): ISortingProps => {
        return {
            disabled: this._isHierarchyView,
            external: this._props.isHierarchicalSort ? this._props.isHierarchicalSort() : true,
            orderBy: this._orderBy,
            onChange: (orderBy) => {
                this._orderBy = orderBy;
                if (this._props.collapseItemsOnSorting) {
                    this._shownChildrenCount = 0;
                    this._expanded = {};
                }

                this._onChange();
            }
        };
    }

    getOrderBy = (): ViewsStore.IOrderBy | ViewsStore.IOrderBy[] | undefined => {
        return this._orderBy;
    }

    allowDrag = () => this._props.allowDrag?.();
    onDragStart = (entity: T): void => {
        const e = entity as any as IHierarchyItem;
        const isExpanded = !!this._expanded[e.hKey];
        if(isExpanded) {
            this._collapse(e.hKey);
            this._onChange();
        }
    }
    isDragDisabled = (entity: T): boolean => {
        const e = entity as any as IHierarchyItem;
        return !!this._expanded[e.hKey];
    }
    
    getFlattened = (): (T & IHierarchyItem)[] => {
        return this._sort(this._items)
            .reduce((cum, cur) => ([...cum, cur, ...this._getSubItems(cur)]), []);
    }

    private _getSubItems = (item: T & IHierarchyItem): (T & IHierarchyItem)[] => {
        let items = this._expanded[item.hKey]?.childItems || [];
        if (!items.length)
            return items;

        const { getItemCategory } = this._props;
        if (getItemCategory) {
            const map = items.reduce((cum, cur) => { const _ = getItemCategory(cur); return { ...cum, [_]: [...(cum[_] || []), cur] }; }, {});
            items = Object.keys(map).reduce((cum, cur) => ([...cum, ...this._sort(map[cur])]), []);
        } else
            items = this._sort(items);

        return items.reduce((cum, cur) => ([...cum, cur, ...this._getSubItems(cur)]), []);
    }

    private _sort = (items: (T & IHierarchyItem)[]): (T & IHierarchyItem)[] => {
        return this._orderBy
            ? items.sort(this._sorter(this._orderBy))
            : items;
    }

    renderItem = (entity: T & IHierarchyItem, _index: number | undefined, field: Metadata.Field, defaultRender: () => JSX.Element) => {
        if (field.id != this._props.fieldId) {
            return defaultRender();
        }

        const outlineLevel = entity.hParentKey ? (this._expanded[entity.hParentKey]?.level || 0) + 1 : 0;
        const isExpanded = !!this._expanded[entity.hKey];

        const isParent = this._props.getItemIsParent
            ? this._props.getItemIsParent(entity)
            : this.allEntities.find(_ => this._props.getItemParentId(_) == this._props.getItemId(entity));
        return <div
            className={`hierarchy-col ${isParent ? 'parent' : ''}`}
            style={{ paddingLeft: (outlineLevel + (isParent ? 0 : 1)) * CHEVRON_WIDTH }}>
            {
                isParent && <EntityChevron
                    isCollapsed={!isExpanded}
                    onClick={e => {
                        isExpanded ? this._collapse(entity.hKey) : this._expand(entity);
                        this._onChange();
                        e.stopPropagation();
                    }} />}
            {defaultRender()}
        </div>;
    }

    private _getHierarchyItem = (entityId: string): T & IHierarchyItem | undefined => {
        const entity = this._items.find(_ => _.id === entityId);
        if (entity) {
            return entity;
        }
        // search IHierarchyItem as child
        for (const key in this._expanded) {
            if (this._expanded.hasOwnProperty(key)) {
                const item = this._expanded[key]?.childItems.find(__ => __.id === entityId);
                if (item) {
                    return item;
                }
            }
        }
        return undefined;
    }

    public expand = (entityId: string, subLevels: number = 0, expandIds: string[] = []): void => {
        const hentity = this._getHierarchyItem(entityId);
        if (!hentity) {
            return;
        }
        this._expand(hentity, subLevels, expandIds);
        this._onChange();
    }

    private _expand = (entity: T & IHierarchyItem, subLevels: number = 0, expandIds: string[] = []) => {
        const entityId = this._props.getItemId(entity);
        const childItems = this.allEntities
            .filter(_ => {
                const id = this._props.getItemId(_);
                const parentId = this._props.getItemParentId(_);
                return id != parentId && parentId == entityId;
            })
            .map(_ => this._buildHierarchyItem(_, entity.hKey));

        const parent = entity.hParentKey ? this._expanded[entity.hParentKey] : undefined;

        this._expanded[entity.hKey] = {
            level: parent ? (parent.level || 0) + 1 : 0,
            item: entity,
            childItems
        }
        this._shownChildrenCount += childItems.length;

        if (subLevels > 0) {
            childItems.forEach(_ => this._expand(_, subLevels - 1));
        }
        if (expandIds.length && childItems.length) {
            const map = childItems.reduce((cum, cur) => ({ ...cum, [cur.id]: cur }), {});
            expandIds.forEach(_ => map[_] && this._expand(map[_], 0, expandIds));
        }
    }

    private _collapse = (key: string) => {
        if (!this._expanded[key])
            return;

        const nodes = this._expanded[key].childItems;
        delete this._expanded[key];
        nodes.forEach(_ => this._collapse(_.hKey));
        this._shownChildrenCount -= nodes.length;
    }

    private _buildHierarchyItem = (item: T, hParentKey?: string): T & IHierarchyItem => ({ ...item, hKey: getId(), hParentKey });
}