import "./EditableGridCell.css";
import * as React from "react";
import { IFormInputComponent } from "../interfaces/IFormInputComponent";
import { DisplayFieldService } from "../DisplayFieldService";
import { Icon, IconButton } from "office-ui-fabric-react";
import { EditorGridCell, getItemValue } from "./EditorGridCell";
import { Dictionary, EntityType, IExtensibleEntity } from "../../../entities/common";
import { IValidator } from "../../../validation";
import { Field } from "../../../entities/Metadata";
import { ViewService } from "../../../services/ViewService";

export type FormatterProps<TImportMap> = { item: TImportMap, value: any };
export type EditorProps<TImportMap> = {
    item: TImportMap,
    value: any,
    onChange: (value: any) => void,
    onEditComplete: (value?: any) => void,
    inputRef?: (_: IFormInputComponent) => void;
};

type FieldCustomProps = {
    customFieldElementRender?: Dictionary<any>,
    customFieldValidatorBuilder?: Dictionary<(state: IExtensibleEntity, field: Field) => IValidator>
};

export type GridCellProps = {
    item: IExtensibleEntity,
    field: Field,
    entityType?: EntityType,
    onEditComplete: (value: any, extraUpdates?: Dictionary<any>) => void,
    withEditIcon?: boolean,
    className?: string,
    readonly?: boolean,
    disableNavigation?: boolean,
} & FieldCustomProps;

export const EditableGridCell = (props: React.PropsWithChildren<GridCellProps>) => {
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [value, setValue] = React.useState(getItemValue(props));

    React.useEffect(() => setValue(getItemValue(props)), [props.item])

    return isEditing
        ? <EditorGridCell {...props}
            onClose={() => setIsEditing(false)}
            onEditComplete={(val: any, extraUpdates?: Dictionary<any>) => {
                setValue(val);
                props.onEditComplete(val, extraUpdates);
            }}
        />
        : <FormatterGridCell {...props} value={value} onEditClick={() => setIsEditing(true)} />;
}

const FormatterGridCell = (props: React.PropsWithChildren<GridCellProps> & { onEditClick: () => void, value: any }) => {
    const onEditClick = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        props.onEditClick();
    }, [props.withEditIcon]);

    const readonlyCustomControl = props.customFieldElementRender?.[props.field.name]?.({}, props.item, props.field, undefined) === null;
    const isReadonly = props.field.isReadonly || !!props.field.isFake || !!props.readonly || readonlyCustomControl;
    const isActionable = ViewService.isActionable(props.field) && !props.readonly;

    const Formatter = DisplayFieldService.getFieldFormatter(props.field, !props.disableNavigation, props.entityType);
    return <div className="grid-editable-cell"
        data-selection-disabled
        onClick={!isReadonly && !props.withEditIcon ? onEditClick : undefined}>
        <div className="formatter" >
            {props.children}
            {!props.children && !!Formatter && <Formatter item={props.item} value={props.value} />}
        </div>
        {!isReadonly && !!props.withEditIcon
            && <div className="grid-edit-button">
                <IconButton iconProps={{ iconName: "Edit" }} onClick={onEditClick} />
            </div>}
        {!isActionable && isReadonly && <span className="readonly-button">
            <Icon iconName="Uneditable" title="This cell is read-only" />
        </span>}
    </div>;
}
