import "./EditableGridCell.css";
import * as React from "react";
import { DisplayFieldService } from "../DisplayFieldService";
import { useDebounce, useOuterClick } from "../../utils/effects";
import { GridCellProps } from "./EditableGridCell";
import { Dictionary } from "../../../entities/common";

export const getItemValue = (props: GridCellProps) => props.item.attributes[props.field.name];

export const EditorGridCell = (props: GridCellProps & { onClose: () => void }) => {
    const [value, setValue] = React.useState(getItemValue(props));
    const [extra, setExtra] = React.useState<Dictionary<any> | undefined>();
    const [isChanged, setIsChanged] = React.useState(false);

    const validator = React.useMemo(() => {
        return props.customFieldValidatorBuilder?.hasOwnProperty(props.field.name)
            ? props.customFieldValidatorBuilder[props.field.name]?.(props.item, props.field)
            : DisplayFieldService.buildValidatorFromField(props.field);
    }, [props.item, props.field, props.customFieldValidatorBuilder]);

    const _onEditComplete = useDebounce<(newValue?: any, extra?: Dictionary<any>) => void>((newValue?: any, newExtra?: Dictionary<any>) => {
        let val = value;
        let extr = extra;
        if (newValue != null) {
            setValue(newValue);
            setExtra(extra);
            setIsChanged(true);
            val = newValue;
            extr = newExtra;
        }

        if (!validator || validator.isValid(val)) {
            if (isChanged) {
                props.onEditComplete(val!, extr);
            }
        } else {
            setValue(getItemValue(props));
            setExtra(undefined);
        }

        props.onClose();
    }, 100); 

    const listener = React.useCallback(() => _onEditComplete(), []);

    const ref = useOuterClick<HTMLDivElement>(listener);
    const inputProps = {
        value: value,
        item: props.item,
        onChanged: (newValue: any, newExtra?: Dictionary<any>) => {
            setValue(newValue);
            setExtra(newExtra);
            setIsChanged(true);
        },
        onBlur: () => _onEditComplete(),
        onEditComplete: _onEditComplete,
        inputRef: (_: any) => { _?.focus(); },
    };

    var editor = React.useMemo(() => {
        return props.customFieldElementRender?.hasOwnProperty(props.field.name)
            ? props.customFieldElementRender[props.field.name]?.(inputProps, props.item, props.field, validator)
            : DisplayFieldService.buildGridEditorFieldInputElement(props.field, inputProps, props.entityType, undefined, undefined, validator);
    }, [props.customFieldElementRender, props.customFieldValidatorBuilder, props.field, props.item, props.entityType, value]);

    const onKeyDown = React.useCallback(e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            _onEditComplete();
        }

        if (e.key === 'Escape') {
            setValue(getItemValue(props));
            setExtra(undefined);
            setIsChanged(false);
            e.preventDefault();
            e.stopPropagation();
            props.onClose();
        }
    }, []);

    return <div ref={ref}
        className={`grid-editable-cell in-edit-mode ${props.className ?? ''}`}
        data-selection-disabled
        onClick={e => e.stopPropagation()}
        onKeyDown={onKeyDown} >
        {editor}
    </div>;
}
